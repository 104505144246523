<!--用户登录-->
<template>
  <!--路径s-->
  <div class="path">
    <el-row :gutter="0">
      <el-col :sm="0" :md="1" :lg="2" ></el-col>
      <el-col :sm="24" :md="22" :lg="20" > 
        <span><a href="/"> 首页</a></span> &nbsp; &gt; &nbsp; <a href="/message/">通知公告</a> &nbsp; &gt; &nbsp; 正文
      </el-col>
      <el-col :sm="0" :md="1" :lg="2"></el-col>
    </el-row>
  </div>
  <!--路径s--> 
  <div class="box-p">
    <el-row :gutter="0">
      <el-col :xs="0" :sm="0" :md="1" :lg="2" ></el-col>
      <el-col :xs="0" :sm="1" :md="1" :lg="1" ></el-col>
      <el-col :xs="24" :sm="22" :md="20" :lg="18">
        <div class="newslist">
            <div class="news_title">{{newsData.title}}</div>   
            <div class="news_tit">发布时间：{{formatDate(newsData.datetimes)}}</div>
            <div class="news_content" v-html="newsData.content"></div>
        </div>
      </el-col>
      <el-col :xs="0" :sm="1" :md="1" :lg="1"></el-col>
      <el-col :xs="0" :sm="0" :md="1" :lg="2" ></el-col>
    </el-row>
  </div>  
</template>

<script>
import {formatDate} from '@/utils/datetime_utils.js'
export default {
  name: 'news',
  data () {
    return {
      newsData: {
        title: '',
        content: '',
        datetimes: ''
      }
    }
  },
  mounted () {
    var id = this.$route.query.id
    if(id === undefined || id === '') {
      this.$message.error('参数错误,打开页面失败。')
      return
    }   
    this.getData(id)
  },
  methods: {   
    async getData (id) {
      const loading = this.$loading({
        lock: true,
        text: '正在加载中...',
        // spinner: 'el-icon-loading',
        // background: 'rgba(0, 0, 0, 0.7)'
      })
      const params = '?id=' + id
      // console.log('params=', params)
      const { data: ret } = await this.$http.get(this.$api.messageshow + params) 
      // console.log('ret=', ret)
      if (ret.code === 1) {
        if(ret.data !== null) {
          this.newsData = ret.data
        }
        // console.log('data=', this.newsData)
      } else {
        this.$message.error(ret.msg)        
      }
      loading.close()
    },
    // 显示时间格式'yyyy-MM-dd hh:mm:ss'
    formatDate(time){
      var data = new Date(time)
      return formatDate(data,'yyyy-MM-dd')
    }    
  }
}
</script>

<style scoped>
.news_title {
  display: block;
  padding: 1.0rem 0;
  font-size: 26px;
  font-weight: bold;
  color:#333;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
}
.news_tit {
  display: block;
  padding: 0.5rem 0;
  font-size: 14px;
  color:#999;
  text-align: center;
}
.news_content {
  display: block;
  padding: 1.5rem 0;
  text-align: justify !important;
  line-height: 180%;
  min-height: 400px;
  color:#666;
}
.news_content img {
  max-width: 100%;
  height: auto;
}
</style>